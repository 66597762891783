var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _c(
        "v-list-item",
        [
          _c(
            "v-list-item-icon",
            [_c("v-icon", [_vm._v("mdi-information")])],
            1
          ),
          _c(
            "v-list-item-content",
            [
              _c("v-list-item-subtitle", {
                staticClass: "text-wrap",
                domProps: {
                  textContent: _vm._s(
                    _vm.$t("timeLine.ActivityTimeLineItemImage.description")
                  )
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-row",
        {
          staticClass: "pa-2"
        },
        [
          _c(
            "v-col",
            {
              attrs: {
                cols: "12"
              }
            },
            [
              _c("file-upload-preview", {
                attrs: {
                  example: _vm.AssetEnum.image,
                  filesCb: _vm.displayImages,
                  displayFiles: _vm.displayImages,
                  onDelete: _vm.handleDelete,
                  onUpload: _vm.handleUpload,
                  isCaptureEnvironment: true,
                  textAlt: "report.additionalImage.textBtnAlt"
                }
              })
            ],
            1
          )
        ],
        1
      ),
      _c(
        "v-card-actions",
        [
          _c(
            "v-btn",
            {
              attrs: {
                text: "",
                disabled: _vm.displayImages.length < 1,
                loading: _vm.isLoading,
                small: "",
                elevation: "0"
              },
              on: {
                click: _vm.clear
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("reset")) + " ")]
          ),
          _c(
            "v-btn",
            {
              attrs: {
                color: "success",
                disabled: _vm.displayImages.length < 1,
                loading: _vm.isLoading,
                small: "",
                elevation: "0"
              },
              on: {
                click: _vm.submit
              }
            },
            [_vm._v(" " + _vm._s(_vm.$t("add")) + " ")]
          )
        ],
        1
      )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }