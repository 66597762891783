var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h

  return _c(
    "div",
    [
      _vm.loading
        ? _c("v-skeleton-loader", {
            attrs: {
              type: "list-item"
            }
          })
        : _c(
            "v-timeline",
            {
              staticClass: "mr-2 ml-2 pb-1",
              attrs: {
                dense: "",
                clipped: ""
              }
            },
            [
              _c("activity-time-line", {
                attrs: {
                  activities: _vm.activities
                }
              })
            ],
            1
          ),
      _c(
        "v-bottom-sheet",
        {
          attrs: {
            inset: ""
          },
          model: {
            value: _vm.commentSheet,
            callback: function callback($$v) {
              _vm.commentSheet = $$v
            },
            expression: "commentSheet"
          }
        },
        [
          _c(
            "v-sheet",
            {
              attrs: {
                height: "400px"
              }
            },
            [
              _c(
                "v-card",
                [
                  _c(
                    "v-card-title",
                    [
                      _c("h1", [
                        _vm._v(
                          _vm._s(
                            _vm.$t(
                              "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.comment"
                            )
                          )
                        )
                      ]),
                      _c("v-spacer"),
                      _c(
                        "v-btn",
                        {
                          attrs: {
                            icon: ""
                          },
                          on: {
                            click: function click($event) {
                              _vm.commentSheet = false
                            }
                          }
                        },
                        [_c("v-icon", [_vm._v(" mdi-close ")])],
                        1
                      )
                    ],
                    1
                  ),
                  _c("report-activity-box-comment-component", {
                    attrs: {
                      source: _vm.source,
                      partnerId: _vm.partner.id
                    },
                    on: {
                      save: function save($event) {
                        _vm.commentSheet = false
                      }
                    }
                  })
                ],
                1
              )
            ],
            1
          )
        ],
        1
      ),
      _vm.$vuetify.breakpoint.mobile
        ? _c("v-divider", {
            staticClass: "d-print-none"
          })
        : _vm._e(),
      _vm.$vuetify.breakpoint.mobile
        ? _c(
            "v-row",
            {
              staticClass: "mb-4 pb-4"
            },
            [
              _c(
                "v-col",
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        flat: ""
                      }
                    },
                    [
                      _vm._l(_vm.mobileMenu, function(action, i) {
                        return [
                          action.isEnabled
                            ? _c(
                                "v-btn",
                                {
                                  key: i,
                                  staticClass: "mt-2",
                                  attrs: {
                                    value: "left",
                                    outlined: "",
                                    color: action.color,
                                    large: "",
                                    block: ""
                                  },
                                  on: {
                                    click: action.action
                                  }
                                },
                                [
                                  _vm._v(" " + _vm._s(action.title) + " "),
                                  _c(
                                    "v-icon",
                                    {
                                      attrs: {
                                        right: ""
                                      }
                                    },
                                    [_vm._v(_vm._s(action.icon))]
                                  )
                                ],
                                1
                              )
                            : _vm._e()
                        ]
                      }),
                      _c("v-card-actions", [_vm._t("actions")], 2)
                    ],
                    2
                  )
                ],
                1
              )
            ],
            1
          )
        : _c(
            "v-row",
            {
              staticClass: "mx-2 mb-4 pb-4"
            },
            [
              _c(
                "v-col",
                {
                  staticClass: "px-0"
                },
                [
                  _c(
                    "v-card",
                    {
                      attrs: {
                        outlined: "",
                        flat: ""
                      }
                    },
                    [
                      _c(
                        "v-tabs",
                        {
                          attrs: {
                            "align-with-title": ""
                          },
                          model: {
                            value: _vm.tab,
                            callback: function callback($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab"
                          }
                        },
                        [
                          _c("v-tabs-slider"),
                          _vm.config.comment
                            ? _c("v-tab", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.comment"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.config.image && _vm.canCreateImage
                            ? _c("v-tab", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.image"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.config.mail && _vm.canCreateMessage
                            ? _c("v-tab", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.email"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e(),
                          _vm.config.document && _vm.canCreateDocument
                            ? _c("v-tab", [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        "timeLine.PartnerReportDetailInfoTimeLineCard.actionCard.upload"
                                      )
                                    ) +
                                    " "
                                )
                              ])
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("v-divider"),
                      _c(
                        "v-tabs-items",
                        {
                          model: {
                            value: _vm.tab,
                            callback: function callback($$v) {
                              _vm.tab = $$v
                            },
                            expression: "tab"
                          }
                        },
                        [
                          _vm.config.comment
                            ? _c(
                                "v-tab-item",
                                {
                                  key: "0"
                                },
                                [
                                  _c("report-activity-box-comment-component", {
                                    attrs: {
                                      source: _vm.source,
                                      partnerId: _vm.partner.id,
                                      isEditorMobile: true
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "actions",
                                          fn: function fn() {
                                            return [_vm._t("actions")]
                                          },
                                          proxy: true
                                        }
                                      ],
                                      null,
                                      true
                                    )
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.config.image && _vm.canCreateImage
                            ? _c(
                                "v-tab-item",
                                {
                                  key: "1"
                                },
                                [
                                  _c("report-activity-box-image-component", {
                                    attrs: {
                                      source: _vm.source,
                                      partner: _vm.partner
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.config.mail && _vm.canCreateMessage
                            ? _c(
                                "v-tab-item",
                                {
                                  key: "2"
                                },
                                [
                                  _c("report-activity-box-mail-component", {
                                    attrs: {
                                      source: _vm.source,
                                      isEditorMobile: true,
                                      partner: _vm.partner,
                                      user: _vm.user,
                                      mail: _vm.mail
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.config.document && _vm.canCreateDocument
                            ? _c(
                                "v-tab-item",
                                {
                                  key: "3"
                                },
                                [
                                  _c("report-activity-box-document-component", {
                                    attrs: {
                                      source: _vm.source,
                                      partnerId: _vm.partner.id
                                    }
                                  })
                                ],
                                1
                              )
                            : _vm._e()
                        ],
                        1
                      ),
                      _c("div", {
                        attrs: {
                          id: _vm.MARKER_ID
                        }
                      })
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }